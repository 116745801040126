import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h1><Link to="/projects">List projects</Link><span>, </span><Link to="/files">list files</Link></h1>
        <hr></hr>
        <p>Available endpoints:</p>
        <ul>
          <li>Download specific package - <code>/getSpecific/&#123;project&#125;/&#123;branch&#125;/&#123;version&#125;/&#123;platform&#125;/file</code></li>
          <li>Get checksum of specific package - <code>/getSpecific/&#123;project&#125;/&#123;branch&#125;/&#123;version&#125;/&#123;platform&#125;/md5</code></li>
          <li>Download specific installer - <code>/getInstaller/&#123;project&#125;/&#123;branch&#125;/&#123;version&#125;/&#123;extension&#125;/file</code></li>
          <li>Get list of latest links for a project and branch - <code>/getLatestLinks/&#123;project&#125;/&#123;branch&#125;</code></li>
          <li>Download latest installer for a project and branch - <code>/getLatestInstaller/&#123;project&#125;/&#123;branch&#125;/&#123;extension&#125;/file</code></li>
          <li>Get checksum of the latest installer for a project and branch - <code>/getLatestInstaller/&#123;project&#125;/&#123;branch&#125;/&#123;extension&#125;/md5</code></li>
          <li>Download latest package for a project and branch - <code>/getLatestPackage/&#123;project&#125;/&#123;branch&#125;/&#123;platform&#125;/file</code></li>
          <li>Get checksum of the latest package for a project and branch - <code>/getLatestPackage/&#123;project&#125;/&#123;branch&#125;/&#123;platform&#125;/md5</code></li>
          <li>List all packages and installers - <code>/list</code></li>
          <li>Upload package - <code>/uploadPackage/&#123;project&#125;/&#123;branch&#125;/&#123;version&#125;/&#123;platform&#125;/file</code></li>
          <li>Register chechsum of a package - <code>/uploadPackage/&#123;project&#125;/&#123;branch&#125;/&#123;version&#125;/&#123;platform&#125;/md5</code></li>
          <li>Upload installer - <code>/uploadInstaller/&#123;project&#125;/&#123;branch&#125;/&#123;version&#125;/&#123;extension&#125;</code></li>
          <li>Download file - <code>/getFile/&#123;filename&#125;/file</code></li>
          <li>Get checksum of file - <code>/getFile/&#123;filename&#125;/md5</code></li>
          <li>List all files - <code>/listFiles</code></li>
          <li>Upload file - <code>/uploadFile/&#123;filename&#125;</code></li>
        </ul>
        <p>Version format:</p>
        <ul>
          <li>Major - <code>unsigned integer</code></li>
          <li>Minor - <code>unsigned integer</code></li>
          <li>Patch - <code>unsigned integer</code></li>
          <li><strong>Format</strong> - <code>&#123;major&#125;.&#123;minor&#125;.&#123;patch&#125;</code></li>
        </ul>
        <p>Platforms:</p>
        <ul>
          <li>Windows 64-bit - <code>win10-x64</code></li>
          <li>Windows 32-bit - <code>win10-x86</code></li>
          <li>Linux 64-bit - <code>linux-x64</code></li>
          <li>Linux Arm 64-bit - <code>linux-arm64</code></li>
        </ul>
        <p>Installer extensions:</p>
        <ul>
          <li>Windows EXE - <code>exe</code></li>
          <li>Windows MSI - <code>msi</code></li>
          <li>Debian DEB - <code>deb</code></li>
        </ul>
      </div>
    );
  }
}
