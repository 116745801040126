import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class VersionDetails extends Component {
    static displayName = VersionDetails.name;

    constructor(props) {
        super(props);
        this.state = { details: null, loading: true};
        this.project = props.match.params.project;
        this.branch = props.match.params.branch;
        this.version = props.match.params.version;
    }

    componentDidMount() {
        this.populateDetails();
    }

    static getTableInstallers(details) {
        if(details.installers.length == 0) {
            return null;
        }

        return (
            <div>
                <h2 id="labelInstallers">Installers</h2>
                <table className='table table-striped' aria-labelledby="labelInstallers">
                    <thead>
                        <tr>
                            <th>Platform</th>
                            <th>Checksum</th>
                            <th>Size</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.installers.map(installer => 
                            <tr key={installer.extensions[1]}>
                                <td>{installer.extensions[0]}</td>
                                <td>{installer.checksum}</td>
                                <td>{installer.size}</td>
                                <td><a href={`/getInstaller/${details.project}/${details.branch}/${details.version}/${installer.extensions[1]}/file`}><strong>Download</strong></a></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    static getTablePackages(details) {
        if(details.packages.length == 0) {
            return null;
        }

        return (
            <div>
                <h2 id="labelPackages">Packages</h2>
                <table className='table table-striped' aria-labelledby="labelPackages">
                    <thead>
                        <tr>
                            <th>Platform</th>
                            <th>Checksum</th>
                            <th>Size</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.packages.map(pkg =>
                            <tr key={pkg.platforms[1]}>
                                <td>{pkg.platforms[0]}</td>
                                <td>{pkg.checksum}</td>
                                <td>{pkg.size}</td>
                                <td><a href={`/getSpecific/${details.project}/${details.branch}/${details.version}/${pkg.platforms[1]}/file`}><strong>Download</strong></a></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    static renderDetails(details) {
        return (
            <div>
                {VersionDetails.getTableInstallers(details)}
                {VersionDetails.getTablePackages(details)}
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : VersionDetails.renderDetails(this.state.details);

        return (
            <div>
                <h4>
                    <Link to="/projects">Projects</Link>
                    <span> / </span>
                    <Link to={`/branches/${this.project}`}>{`${this.project}`}</Link>
                    <span> / </span>
                    <Link to={`/versions/${this.project}/${this.branch}`}>{this.branch}</Link>
                    <span> / </span>
                    <span>{this.version}</span>
                </h4>
                <h1>
                    <span>Packages and installers of </span>
                    <Link to={`/branches/${this.project}`}>{this.project}</Link>
                    <span> / </span>
                    <Link to={`/versions/${this.project}/${this.branch}`}>{this.branch}</Link>
                    <span> / </span>
                    <span>{this.version}</span>
                </h1>
                <p>List of all registered packages and installers of version {this.version} on branch {this.branch} in project {this.project}</p>
                {contents}
            </div>
        );
    }

    async populateDetails() {
        const response = await fetch(`version/getDetailedVersionStats/${this.project}/${this.branch}/${this.version}`);
        const data = await response.json();
        this.setState({ details: data, loading: false });
    }
}