import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Versions extends Component {
    static displayName = Versions.name;

    constructor(props) {
        super(props);
        this.state = { versions: [], loading: true };
        this.project = props.match.params.project;
        this.branch = props.match.params.branch;
    }

    componentDidMount() {
        this.populateVersions();
    }

    static getRandomKey() {
        return Math.floor(Math.random() * 100000);
    }

    static getDirectLinkForVersion(project, branch, version, packagePlatform, content) {
        return (
            <a key={Versions.getRandomKey()} href={`/getSpecific/${project}/${branch}/${version}/${packagePlatform}/file`} target="_blank">{content}</a>
        );
    }

    static getAllPackagePlatforms(project, branch, version, packagePlatforms) {
        if(packagePlatforms.length === 0) {
            return ( <em>None</em> );
        }

        let linkContents = packagePlatforms.map(pp => Versions.getDirectLinkForVersion(project, branch, version, pp[1], pp[0]));
        let linkComplete = linkContents.map(link => [link, (<span key={Versions.getRandomKey()}>, </span>)]).flat();
        linkComplete.pop();
        return linkComplete;
    }

    static getDirectLinkForInstaller(project, branch, version, extension, content) {
        return (
            <a key={Versions.getRandomKey()} href={`/getInstaller/${project}/${branch}/${version}/${extension}/file`}>{content}</a>
        );
    }

    static getAllInstallers(project, branch, version, installers) {
        if(installers.length === 0) {
            return ( <em>None</em> );
        }

        let linkContents = installers.map(inst => Versions.getDirectLinkForInstaller(project, branch, version, inst[1], inst[0]));
        let linkComplete = linkContents.map(link => [link, (<span key={Versions.getRandomKey()}>, </span>)]).flat();
        linkComplete.pop();
        return linkComplete;
    }

    static renderVersions(versions, project, branch) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Version</th>
                        <th>Packages</th>
                        <th>Installers</th>
                    </tr>
                </thead>
                <tbody>
                    {versions.map((version, i) => 
                        <tr key={version.version} class={i === 0 ? "table-success" : ""}>
                            <td><Link to={`/versionDetails/${project}/${branch}/${version.version}`}>{version.version}</Link></td>
                            <td>{Versions.getAllPackagePlatforms(project, branch, version.version, version.packagePlatforms)}</td>
                            <td>{Versions.getAllInstallers(project, branch, version.version, version.installerPlatforms)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Versions.renderVersions(this.state.versions, this.project, this.branch);

        return (
            <div>
                <h4>
                    <Link to="/projects">Projects</Link>
                    <span> / </span>
                    <Link to={`/branches/${this.project}`}>{`${this.project}`}</Link>
                    <span> / </span>
                    <span>{this.branch}</span>
                </h4>
                <h1 id="tableLabel">Versions of <Link to={`/branches/${this.project}`}>{this.project}</Link> / {this.branch}</h1>
                <p>List of all registered versions of branch {this.branch} in project {this.project}</p>
                {contents}
            </div>
        );
    }

    async populateVersions() {
        const response = await fetch(`version/getVersionStats/${this.project}/${this.branch}`);
        const data = await response.json();
        this.setState({ versions: data, loading: false });
    }
}