import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Projects extends Component {
    static displayName = Projects.name;

    constructor(props) {
        super(props);
        console.log(props);
        this.state = { projectCounts: [], loading: true };
    }

    componentDidMount() {
        this.populateProjectCountData();
    }

    static renderProjectCountTable(projectCounts) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Project</th>
                        <th>Branches</th>
                        <th>Installers</th>
                        <th>Packages</th>
                    </tr>
                </thead>
                <tbody>
                    {projectCounts.map(projectCount => 
                            <tr key={projectCount.name}>
                                <td><Link to={`/branches/${projectCount.name}`}>{projectCount.name}</Link></td>
                                <td>{projectCount.branchCount}</td>
                                <td>{projectCount.installerCount}</td>
                                <td>{projectCount.packageCount}</td>
                            </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Projects.renderProjectCountTable(this.state.projectCounts);

        return (
            <div>
                <h4>
                <span>Projects</span>
                </h4>
                <h1 id="tableLabel">Projects</h1>
                <p>List of all registered projects</p>
                {contents}
            </div>
        );
    }

    async populateProjectCountData() {
        const response = await fetch('project/getProjectCounts');
        const data = await response.json();
        this.setState({ projectCounts: data, loading: false });
    }
}