import React, { Component } from 'react';

export class Files extends Component {
    static displayName = Files.name;

    constructor(props) {
        super(props);
        this.state = { files: null, loading: true };
    }

    componentDidMount() {
        this.populateFiles();
    }

    static renderFiles(files) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Checksum</th>
                        <th>Size</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file =>
                        <tr>
                            <td>{file.Name}</td>
                            <td>{file.MD5}</td>
                            <td>{file.Size}</td>
                            <td><a href={`/getFile/${file.Name}/file`}><strong>Download</strong></a></td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Files.renderFiles(this.state.files);

        return (
            <div>
                <h4>
                    <span>Files</span>
                </h4>
                <h1 id="tableLabel">All registered files</h1>
                {contents}
            </div>
        );
    }

    async populateFiles() {
        const response = await fetch('listFiles');
        const data = await response.json();
        console.log(data);
        this.setState({ files: data, loading: false });
    }
}