import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Branches extends Component {
    static displayName = Branches.name;

    constructor(props) {
        super(props);
        console.log(props);
        this.state = { branches: [], loading: true };
        this.project = props.match.params.project;
    }

    componentDidMount() {
        this.populateBranches();
    }

    static getTableRowClassForBranchName(branch) {
        switch (branch) {
            case "master":
            case "main":
                return "table-success";
            case "rc":
                return "table-warning";
            default:
                return "table-danger";
        }
    }

    static renderBranches(branches, project) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Branch</th>
                        <th>Version</th>
                        <th>Packages for</th>
                        <th>Installers for</th>
                        <th>Total packages</th>
                        <th>Total installers</th>
                    </tr>
                </thead>
                <tbody>
                    {branches.map(branch =>
                        <tr key={branch.name} class={Branches.getTableRowClassForBranchName(branch.name)}>
                            <td><Link to={`/versions/${project}/${branch.name}`}>{branch.name}</Link></td>
                            <td>{branch.latestVersion}</td>
                            <td>{branch.latestPackagePlatforms}</td>
                            <td>{branch.latestInstallerPlatforms}</td>
                            <td>{branch.packageCount}</td>
                            <td>{branch.installerCount}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Branches.renderBranches(this.state.branches, this.project);

        return (
            <div>
                <h4>
                    <Link to="/projects">Projects</Link>
                    <span> / </span>
                    <span>{this.project}</span>
                </h4>
                <h1 id="tableLabel">Branches of {this.project}</h1>
                <p>List of all registered branches in project {this.project}</p>
                {contents}
            </div>
        );
    }

    async populateBranches() {
        const response = await fetch(`branch/getBranchStats/${this.project}`);
        const data = await response.json();
        this.setState({ branches: data, loading: false });
    }
}