import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';

import './custom.css'
import { Projects } from './components/Projects';
import { Branches } from './components/Branches';
import { Versions } from './components/Versions';
import { VersionDetails } from './components/VersionDetails'
import { Files } from './components/Files'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/projects' component={Projects} />
        <Route path='/branches/:project' component={Branches} />
        <Route path='/versions/:project/:branch' component={Versions} />
        <Route path='/versionDetails/:project/:branch/:version' component={VersionDetails} />
        <Route path='/files' component={Files} />
      </Layout>
    );
  }
}
